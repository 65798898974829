import React from "react";
import _ from "lodash";

import components, { Layout } from "../components/index";
import { markdownify } from "../utils";

export default class Home extends React.Component {
  render() {
    return (
      <Layout
        {...this.props}
        lang={_.get(this.props, "pageContext.frontmatter.lang")}
      >
        <section className="wrapper wrapper--header">
          <header className="inner">
            <h1>{_.get(this.props, "pageContext.frontmatter.title")}</h1>
            {markdownify(_.get(this.props, "pageContext.frontmatter.subtitle"))}
          </header>
        </section>
        {_.map(
          _.get(this.props, "pageContext.frontmatter.sections"),
          (section, section_idx) => {
            let GetSectionComponent = components[_.get(section, "component")];
            return (
              <GetSectionComponent
                key={section_idx}
                {...this.props}
                section={section}
                page={this.props.pageContext}
                site={this.props.pageContext.site}
              />
            );
          }
        )}
      </Layout>
    );
  }
}
